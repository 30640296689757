<template>
    <div>
        <!-- <van-nav-bar
        title="用户绑定"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        /> -->
      <div style="padding: 8vh 5vw;">
          
          
          <div style="font-size: 5vw;text-align: start;font-weight: bold;margin-bottom:20px;">登录</div>
          <van-field v-model="username" type="tel" placeholder="请输入手机号" style="background-color:#f3f4f8;margin: 8px 0;border-radius: 4px;" />
          <div style="display: flex;align-items: center;">
            <van-field v-model="code" type="text"  placeholder="请输入验证码" style="flex:3;background-color:#f3f4f8;border-radius: 4px;"/>
            <van-button type="info" :disabled="messageDisplay" style="flex:2;margin-left: 20px;" @click="getMessageCode">{{sendMessageBtnText}}</van-button>
          </div>
          
          <!-- <div style="font-size: 3.5vw;text-align: start;padding-top: 4vw;color: #999;">首次登录的密码即视为后续登录密码，请牢记。</div> -->
          <van-button type="info" style="margin-top:12vw;width: 100%;" @click="toLogin">登录</van-button>
          <!-- <van-button type="default" style="margin-top:10px;width: 100%;">绑定设备</van-button> -->
      </div>


    </div>
    
</template>

<script>
import { login,checkLoginStatus, sendMessageCaptchaCode } from "@/api/system/login";


export default {
  data() {
    return {
      show: false,
      username:'',
      password:'',
      wxcode: 'aaa123',
      code:'',
      uuid:'',
      state:'',
      url:null,
      messageDisplay: false,
      sendMessageBtnText:"获取验证码",
      second:60
    };
  },

  created(){
    this.url = this.$route.query.url;
    checkLoginStatus().then(res=>{
      console.log("已经登录");
      // this.$router.push('loginsuccess')
    }).catch(error=>{
      console.log("未登录");
    })


    const searchParams = new URLSearchParams(window.location.search);
    this.wxcode = searchParams.get('code')
    this.state = searchParams.get('state')
    console.log('this.state',this.state);
    // const url1 = location.href;
    // var reg1 = new RegExp("login"); // 加'g'，删除字符串里所有的"a"
    // const url2 = url1.replace(reg1,"");
    //this.$toast(location.href);
    this.username = window.localStorage.getItem("tel_record");
    window.localStorage.setItem("loginUrl",location.href)


    
  },

  methods: {

    getMessageCode(){

      if(this.username ==null || this.username == ''){
        this.$toast.fail('手机号码不能为空！')
        return;
      }
      // var reg = /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,6,7,8])|(18[0-9])|(19[8,9]))\d{8}/;
      // this.username =this.username.replace(/^\s+|\s+$/g,"");
      // if (!reg.test(this.username)) {
      //   return this.$toast.fail('手机号码格式有误！')
      // }
      let tel = this.username;
      //测试
      //tel = '13163679357'
      this.messageDisplay = true
      sendMessageCaptchaCode(tel).then(res=>{
        this.uuid = res.uuid
        this.$toast('短信验证码已发送！')
        this.coutdown()
      }).catch(err=>{
        console.log('err',err.data.msg);
        this.$toast(err.data.msg)
        this.messageDisplay = false;
      })
    },

    coutdown(){
      let result = setInterval(() => {
          
          --this.second;
          this.sendMessageBtnText = "已发送("+this.second+")"
          if (this.second < 1) {
            clearInterval(result);
            this.messageDisplay = false;
            //this.disabled = false;
            this.second = 60;
            this.sendMessageBtnText = "获取验证码"
          }
        }, 1000);
    },

    checkLoginStatus(){
      checkLoginStatus().then(res=>{
        this.$router.push('loginsuccess');
      }).catch(error=>{
        this.toLogin();
      })
    },

    toLogin(){
      const username = this.username.trim()
      const password = this.password
      const code = this.code.trim();
      
      login(username, password,code,this.uuid,this.wxcode).then(res => {
        console.log('token',res.loginResultMap);
        const token = res.loginResultMap.token
        const userId = res.loginResultMap.userId
        window.localStorage.setItem("Token",token)
        window.localStorage.setItem("userId",userId)
        window.localStorage.setItem("tel_record",username)
        if(this.state==null){
          this.$router.push('loginsuccess')
        }else{
          this.$router.push(this.state)
        }
        
      }).catch(error => {
          console.log(error);

          if(error.data.code == 610){
            console.log('登录失败，刷新页面')
            location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/&response_type=code&scope=snsapi_base&state=loginsuccess#wechat_redirect'
				  }


      })
    },

    showPopup() {
      this.show = true;
    },

    onClickLeft(){

    },


    
  },
};
</script>