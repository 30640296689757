<template>
    <div style="background-color: #f3f4f8;height: 100%;padding: 15px;box-sizing: border-box;">
        
        <div style="display: flex;justify-content: space-between;align-items: center;background-color: white;padding: 20px;margin-top: 10px;" >
            <div>
                <div style="text-align: start;font-weight: bold;font-size: 4vw;margin-bottom: 1.5vw;">修改预留手机号</div>
                <div style="color: #a6a6a6;font-size: 3.5vw;">工单状态：已通过审核</div>
                <div style="color: #a6a6a6;font-size: 3.5vw;">创建时间：2024-06-18</div>
            </div>
            <div style="font-weight: bold;font-size: 5vw;"><van-icon name="arrow" /></div>
        </div>


  </div>
</template>

<script>
export default {
    data() {
        return {
          
        };
    },

    created(){
       
       
    },

    methods: {

        
    
    },
}
</script>

<style>

</style>