import request from '@/utils/request'

// 登录方法
export function getSignature(timestamp, noncestr, url1) {

  return request({
    url: '/gzh/getSignature?timestamp=' + timestamp + '&noncestr=' + noncestr + '&url=' + url1,
    headers: {
      isToken: false
    },
    method: 'get'
  })

}