import request from '@/utils/request'


// 重置密码
export function resetPwd(user) {

  return request({
    url:'/system/user/resetPwd',
    method: 'PUT',
    data: user
  })
}

// 修改手机号
export function changeTel(user) {

  return request({
    url:'/system/user/changeTel',
    method: 'PUT',
    data: user
  })
}



// 修改手机号
export function getUserBaseInfo() {

  return request({
    url:'/system/user/getUserBaseInfo',
    method: 'GET',
  })
}