<template>
  <div style="padding: 10vw;position: relative;height: 100%;box-sizing: border-box;">
   <div >
     <el-input placeholder="请输入您的原手机号" v-model="oldtel" style="margin: 0px 0 15px 0px;"><template slot="prepend">原手机号</template></el-input>

     <div style="display: flex;">
      <el-input placeholder="验证码" v-model="code" ></el-input>
      <el-button  type="primary" :disabled="messageDisplay" style="margin: 0px 0 0px 15px;" @click="getMessageCode">{{sendMessageBtnText}}</el-button>
     </div>
     
     <el-input placeholder="请输入您的新手机号" v-model="newtel" style="margin: 15px 0;"><template slot="prepend">新手机号</template></el-input>

     
   </div>
   <div style="float: right;">
    <span @click="toShuoming" style="margin-top: 15px;color: #2596ff;text-align: end;">原手机号码不能用？</span>
   </div>
   
   <div style="position: absolute;bottom: 10vw;transform: translate(-50%);left: 50%;background-color: #409eff;color: white;height: 10vw;width: 90%;;line-height:10vw;border-radius: 1vw;" @click="comfirm">完成</div>
   
 </div>
</template>

<script>
import { changeFamerUserTel } from "@/api/wushui/wushui";
import { sendMessageCaptchaCode } from "@/api/system/login";
export default {

 data() {
   return {
     oldtel:'',
     newtel:'',
     uuid:'',
     code:'',
     second:60,
     messageDisplay: false,
     sendMessageBtnText:"获取验证码",
   }
 },
 created(){
   
 },
 methods: {
    comfirm(){
      if(this.newtel ==null || this.newtel == ''){
       this.$toast.fail('手机号码不能为空！')
       return;
     }
     if(this.code ==null || this.code == ''){
       this.$toast.fail('验证码不能为空！')
       return;
     }
     var reg = /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,7,8])|(18[0-9])|(19[8,9]))\d{8}/;
				this.newtel =this.newtel.replace(/^\s+|\s+$/g,"");
				if (!reg.test(this.newtel)) {
					return this.$toast.fail('手机号码格式有误！')
				}
      this.$dialog.confirm({
        title: '是否确认修改手机号码？',
        message: '该行为可能导致设备绑定失败，请先与管理员确认后再进行操作',
      })
      .then(() => {
        const userId = window.localStorage.getItem("userId");
    
        const user = {
          userId:userId,
          newtel:this.newtel,
          oldtel:this.oldtel,
          code: this.code,
          uuid: this.uuid
        }
        console.log(user);
        changeFamerUserTel(user).then(res=>{
          this.$toast.success('修改成功！')
          this.oldtel = '';
          this.newtel = '';
          this.code = '';
          this.messageDisplay = false;

        })
      })
      .catch(() => {
        // on cancel
      });

   },

    getMessageCode(){

      if(this.oldtel ==null || this.oldtel == ''){
        this.$toast.fail('手机号码不能为空！')
        return;
      }
      var reg = /^((13\d)|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[0,1,3,5,7,8])|(18[0-9])|(19[8,9]))\d{8}/;
      this.oldtel =this.oldtel.replace(/^\s+|\s+$/g,"");
      if (!reg.test(this.oldtel)) {
        return this.$toast.fail('手机号码格式有误！')
      }
      let tel = this.oldtel;
      //测试
      //tel = '13163679357'
      this.messageDisplay = true
      sendMessageCaptchaCode(tel).then(res=>{
          this.uuid = res.uuid
          this.$toast('短信验证码已发送！')
          this.coutdown()
        }).catch(err=>{
          console.log('err',err.data.msg);
          this.$toast(err.data.msg)
          this.messageDisplay = false;
        })
    },

    coutdown(){
      let result = setInterval(() => {
          
          --this.second;
          this.sendMessageBtnText = "已发送("+this.second+")"
          if (this.second < 1) {
            clearInterval(result);
            this.messageDisplay = false;
            //this.disabled = false;
            this.second = 60;
            this.sendMessageBtnText = "获取验证码"
          }
        }, 1000);
    },

    toShuoming(){
      this.$router.push('/shuoming');
    }


 }
}
</script>

<style>

</style>