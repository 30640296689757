<template>
   <div style="padding: 10vw;">
    <div >
      
      <el-input placeholder="请输入您的密码" v-model="input1" show-password><template slot="prepend">设置密码</template></el-input>
      
    </div>

    <div style="margin-top: 5vw;">
      <el-input placeholder="请再次确认您的密码" v-model="input2" show-password><template slot="prepend">确认密码</template></el-input>
    </div>
    <div style="margin-top: 7vw;background-color: #fb4e3e;color: white;height: 10vw;line-height:10vw;border-radius: 1vw;" @click="comfirm">完成</div>
  </div>
</template>

<script>
import { resetPwd } from "@/api/system/user";

export default {
 
  data() {
    return {
      input1:'',
      input2:''
    }
  },
  created(){
    
  },
  methods: {
    comfirm(){
      if(this.input1 ==null || this.input1 == ''||this.input2 ==null || this.input2 == ''){
        this.$toast.fail('密码为空！')
        return;
      }
      if(this.input1!=this.input2){
        this.$toast.fail('两次密码输入不一致')
        return;
      }
      const userId = window.localStorage.getItem("userId");
      
      const user = {
        userId:userId,
        password:this.input1
      }
      console.log(user);
      resetPwd(user).then(res=>{
        this.$toast.success('修改成功！')
      })

    }


  }
}
</script>

<style>

</style>