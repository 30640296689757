<template>
  <div>
    <div class="pageOne" v-show="pageNum==1" style="position: relative;min-height: 100vh;background-color: #f3f4f8;padding: 10px;box-sizing: border-box;">
      <div style="display: flex;align-items: center;border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding-left: 15px;">
        <div style="text-align: start;margin-right: 20px;">证件类型</div>
        <van-radio-group v-model="userInfo.oldIDType" direction="horizontal" style="margin: 20px;">
          <van-radio name="1">身份证</van-radio>
          <van-radio name="2">户口簿</van-radio>
        </van-radio-group>
      </div>
      <div style="border-radius:5px;">
          <van-field v-model="userInfo.oldName" label="原户主名" placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      <div v-if="userInfo.oldIDType == '1'" style="border-radius:5px;margin: 10px 0 ;font-size: 4vw;">
          <van-field v-model="userInfo.oldNumber" label="身份证号" placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      <div v-if="userInfo.oldIDType == '2'" style="border-radius:5px;margin: 10px 0 ;font-size: 4vw;">
          <van-field v-model="userInfo.oldNumber" label="户口编号" placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      
      
      <div v-if="userInfo.oldIDType == '1'">
        <div style="border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding: 15px;">
          <div style="text-align: start;">身份证正面（必填）</div>
          <van-uploader :after-read="afterRead" v-model="oldCard1" preview-size="200px" image-fit="contain" name="oldCard1">
            <div v-if="oldCard1.length == 0" style="background-color: #f3f4f8;margin: 10px;border-radius: 5px;padding: 30px 0;width: 80vw;" @click="takePhoto('oldCard1')"><img width="120px" height="120px" src="../assets/camera.png" /></div>
            <div v-else></div>
          </van-uploader>
        </div>
        <div style="border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding: 15px;">
          <div style="text-align: start;">身份证正面（必填）</div>
          <van-uploader :after-read="afterRead" v-model="oldCard2" preview-size="200px" image-fit="contain" name="oldCard2">
            <div v-if="oldCard2.length == 0" style="background-color: #f3f4f8;margin: 10px;border-radius: 5px;padding: 30px 0;width: 80vw;" @click="takePhoto('oldCard2')"><img width="120px" height="120px" src="../assets/camera.png" /></div>
            <div v-else></div>
          </van-uploader>
        </div>
      </div>
      <div v-if="userInfo.oldIDType == '2'">
        <div style="border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding: 15px;">
          <div style="text-align: start;">户口页</div>
          <van-uploader :after-read="afterRead" v-model="oldBook" preview-size="200px" image-fit="contain">
            <div v-if="oldBook.length == 0" style="background-color: #f3f4f8;margin: 10px;border-radius: 5px;padding: 30px 0;width: 80vw;" @click="takePhoto('oldBook')"><img width="120px" height="120px" src="../assets/camera.png" /></div>
            <div v-else></div>
          </van-uploader>
        </div>
      </div>
      
      <div style="height:80px;"></div>
      <van-button type="info" style="position: absolute;bottom: 30px;transform: translate(-50%);left: 50%;;width: 90%;"
        @click="nextPage">下一步</van-button>

      
    </div>

    <div class="pageTwo" v-show="pageNum == 2" style="position: relative;min-height: 100vh;background-color: #f3f4f8;padding: 10px;box-sizing: border-box;">
      <div style="display: flex;align-items: center;border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding-left: 15px;">
        <div style="text-align: start;margin-right: 20px;">证件类型</div>
        <van-radio-group v-model="userInfo.newIDType" direction="horizontal" style="margin: 20px;">
          <van-radio name="1">身份证</van-radio>
          <van-radio name="2">户口簿</van-radio>
        </van-radio-group>
      </div>

      <div style="border-radius:5px;">
          <van-field v-model="userInfo.newName" label="新户主名" placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      <div v-if="userInfo.newIDType =='1'" style="border-radius:5px;margin: 10px 0 ;font-size: 4vw;">
          <van-field v-model="userInfo.newNumber" label="身份证号" placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      <div v-if="userInfo.newIDType =='2'" style="border-radius:5px;margin: 10px 0 ;font-size: 4vw;">
          <van-field v-model="userInfo.newNumber" label="户口编号" placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      <div v-if="userInfo.newIDType == '1'">
        <div style="border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding: 15px;">
          <div style="text-align: start;">身份证正面（必填）</div>
          <van-uploader :after-read="afterRead" v-model="newCard1" preview-size="200px" image-fit="contain" name="newCard1">
            <div v-if="newCard1.length == 0" style="background-color: #f3f4f8;margin: 10px;border-radius: 5px;padding: 30px 0;width: 80vw;" @click="takePhoto('newCard1')"><img width="120px" height="120px" src="../assets/camera.png" /></div>
            <div v-else></div>
          </van-uploader>
       
        </div>
        <div style="border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding: 15px;">
          <div style="text-align: start;">身份证正面（必填）</div>
          <van-uploader :after-read="afterRead" v-model="newCard2" preview-size="200px" image-fit="contain" name="newCard2">
            <div v-if="newCard2.length == 0" style="background-color: #f3f4f8;margin: 10px;border-radius: 5px;padding: 30px 0;width: 80vw;" @click="takePhoto('newCard2')"><img width="120px" height="120px" src="../assets/camera.png" /></div>
            <div v-else></div>
          </van-uploader>
        </div>
      </div>
      <div v-if="userInfo.newIDType == '2'">
        <div style="border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding: 15px;">
          <div style="text-align: start;">户口页</div>
          <van-uploader :after-read="afterRead" v-model="newBook" preview-size="200px" image-fit="contain">
            <div v-if="newBook.length == 0" style="background-color: #f3f4f8;margin: 10px;border-radius: 5px;padding: 30px 0;width: 80vw;" @click="takePhoto('newBook')"><img width="120px" height="120px" src="../assets/camera.png" /></div>
            <div v-else></div>
          </van-uploader>
        </div>
      </div>
      
      <div style="height:150px;"></div>
      <div style="display: flex;flex-direction: column;position: absolute;bottom: 30px;transform: translate(-50%);left: 50%;width: 90%;">
        <van-button type="info" plain  style="margin-bottom: 20px;"
        @click="back">上一步</van-button>
        <van-button type="info" style=""
        @click="confirmOrder">提交</van-button>
      </div>


      
    </div>
  
  </div>
  


</template>

<script>
import {applyWorkOrder} from '@/api/wushui/wushui'

export default {
    data() {
        return {
          userInfo:{
            oldIDType:'1',//1身份证2户口簿
            oldName:'',
            oldNumber:'',
            newIDType:'1',
            newName:'',
            newNumber:'',
            operationWay:'1',
            category:'1'
          },
          oldCard1:[],
          oldCard2:[],
          oldBook:[],
          newCard1:[],
          newCard2:[],
          newBook:[],
          pageNum:1,
          fileMap:{}
        };
    },

    created(){
        this.initPage();
    },

    methods: {


      afterRead(file) {
        // 此时可以自行将文件上传至服务器
        console.log('afterRead',file);
      // console.log('oldCard1',this.oldCard1[0].file);
        

      },

      confirmOrder(){
        this.$dialog.confirm({
          title: '是否确认提交工单',
          //message: '弹窗内容',
        }).then(() => {
          let format = new window.FormData();
          if(this.userInfo.oldIDType == 1){//身份证
            // this.fileMap.oldCard1 = this.oldCard1[0].file
            // this.fileMap.oldCard2 = this.oldCard2[0].file
            if(this.oldCard1.length == 0){
              this.$toast('原户主身份证正面未上传！');
              return;
            }
            if(this.oldCard2.length == 0){
              this.$toast('原户主身份证背面未上传！');
              return;
            }
            format.append("oldCard1",this.oldCard1[0].file);
            format.append("oldCard2",this.oldCard2[0].file);

          }else{//户口簿
            //this.fileMap.oldBook = this.oldBook[0].file
            if(this.oldBook.length == 0){
              this.$toast('原户主户口照片未上传！');
              return;
            }
            format.append("oldBook",this.oldBook[0].file);
          }

          if(this.userInfo.newIDType == 1){//身份证
            // this.fileMap.newCard1 = this.newCard1[0].file
            // this.fileMap.newCard2 = this.newCard2[0].file 
            if(this.newCard1.length == 0){
              this.$toast('新户主身份证正面未上传！');
              return;
            }
            if(this.newCard2.length == 0){
              this.$toast('新户主身份证背面未上传！');
              return;
            }
            format.append("newCard1",this.newCard1[0].file);
            format.append("newCard2",this.newCard2[0].file);
          }else{//户口簿
            if(this.newBook.length == 0){
              this.$toast('新户主户口照片未上传！');
              return;
            }
            // this.fileMap.newBook = this.newBook[0].file
            format.append("newBook",this.newBook[0].file);
          }

          //format.append("files",files)
          format.append("userInfo", new Blob([JSON.stringify(this.userInfo)], {type: "application/json"}));
          console.log('fffff',format)

          applyWorkOrder(format)
          .then(res=>{
            this.$router.push('/successPage');
          })

          
        });
      },

      nextPage(){
        if(!this.userInfo.oldName){
          this.$toast('原户主名为空！');
          return;
        }
        
        if(this.userInfo.oldIDType == '1'){
          if(!this.userInfo.oldNumber){
            this.$toast('原户主身份证号为空！');
            return;
          }
          if(this.oldCard1.length == 0){
            this.$toast('原户主身份证正面未上传！');
            return;
          }
          if(this.oldCard2.length == 0){
            this.$toast('原户主身份证背面未上传！');
            return;
          }
        }
        if(this.userInfo.oldIDType == '2'){
          if(!this.userInfo.oldNumber){
            this.$toast('原户主户口编号为空！');
            return;
          }
          if(this.oldBook.length == 0){
            this.$toast('原户主户口照片未上传！');
            return;
          }
        }
        this.$toast.loading({
          forbidClick: true,
          duration:1000
        });
        setTimeout(() => {
          this.pageNum = 2
        }, 1000);
       
      },
      back(){
        this.$toast.loading({
          forbidClick: true,
          duration:1000
        });
        setTimeout(() => {
          this.pageNum = 1
        }, 1000);
      },
    
      //查询设备列表
      initPage(){
   
      },


    },
}
</script>

<style>

</style>