<template>
   <div style="background-color: #f3f4f8;height: 100%;padding: 15px;box-sizing: border-box;">
    <div style="display: flex;justify-content: space-between;align-items: center;background-color: white;padding: 20px;"  @click="toEditPages('1')">
      <div>
        <div style="text-align: start;font-weight: bold;font-size: 4vw;margin-bottom: 3vw;">修改手机号</div>
        <div style="color: #a6a6a6;font-size: 3.5vw;">更换您登陆补贴程序的账号</div>
      </div>
      <div style="font-weight: bold;font-size: 5vw;"><van-icon name="arrow" /></div>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: center;background-color: white;padding: 20px;margin-top: 10px;" @click="myOperations" >
      <div>
        <div style="text-align: start;font-weight: bold;font-size: 4vw;margin-bottom: 3vw;">我的申请记录</div>
        <div style="color: #a6a6a6;font-size: 3.5vw;">查看此微信号申请记录处理情况</div>
      </div>
      <div style="font-weight: bold;font-size: 5vw;"><van-icon name="arrow" /></div>
    </div>
    <!-- <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 10px;background-color: white;padding: 20px;" @click="toEditPages('2')">
      <div>
        <div style="text-align: start;font-weight: bold;font-size: 4vw;margin-bottom: 3vw;">修改用户名</div>
        <div style="color: #a6a6a6;font-size: 3.5vw;">更换您在设备安装时预留的户主信息</div>
      </div>
      <div style="font-weight: bold;font-size: 5vw;"><van-icon name="arrow" /></div>
    </div> -->

  </div>
</template>

<script>
import { resetPwd } from "@/api/system/user";

export default {
 
  data() {
    return {
     
      
    }
  },
  created(){
    
  },
  methods: {
    
    toEditPages(val){

      if(val == "1"){
        // this.$router.push('/changeTelNew');
        location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/%23%2FchangeTelNew&response_type=code&scope=snsapi_base&state=loginsuccess#wechat_redirect'

      }else if(val == "2"){
         this.$router.push('/editUserBaseInfo');
        

      }

    },
    myOperations(){
      // this.$router.push('/myOperationList');
      location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/%23%2FmyOperationList&response_type=code&scope=snsapi_base&state=loginsuccess#wechat_redirect'
    }
    


  }
}
</script>

<style>


</style>