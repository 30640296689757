import request from '@/utils/request'


// 登录方法
export function login(username, password,code,uuid,wxcode) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  // appid = 'wx7ebdabb02e6faa33'
  
  
  const loginUrl ='/login/'+wxcode;
  console.log(loginUrl)
  return request({
    url:loginUrl,
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}


export function checkLoginStatus() {

  const checkurl = '/checkLoginStatus'
  return request({
    url: checkurl,
    method: 'get',
  })
}


// 退出登录方法
export function logofUser() {

  return request({
    url:"/logofUser",
    method: 'post'
  })
}


// 获取短信验证码
export function sendMessageCaptchaCode(tel) {

  return request({
    url:"/sendMessageCaptchaCode/"+tel,
    headers: {
      isToken: false
    },
    method: 'post'
  })
}