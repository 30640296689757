<template>
    <div>
        <div style="margin-top: 10vh;font-size: 30px;">登录成功 !</div>
        <div style="margin-top: 5vh;font-size: 20px;">即将在{{second}}秒后自动关闭页面</div>
        <van-button plain round type="info" style="margin-top: 2vh;" @click="closeImmediately">立即关闭</van-button>
    </div>
</template>

<script>
import {getSignature} from '@/api/system/weixin';
import wx from 'weixin-js-sdk'

export default {
    data(){
        return {
            mtoken:"",
            second: 3,
            sit:null,
            realAuthUrl:''
        }
    },
    mounted(){
        
        this.sit = setInterval(this.countdown,1000)
        //this.closeWindow();
        const loginUrl = window.localStorage.getItem('loginUrl');
       // this.$toast(loginUrl);
        this.realAuthUrl = loginUrl
        
    },
    
    methods:{
        //立即关闭页面
        closeImmediately(){
            clearInterval(this.sit);
            this.closeWindow();
        },

        countdown(){
            if(this.second <= 0){
                clearInterval(this.sit);
                this.closeWindow();
                return;
            }
            this.second--;
        },

        closeWindow(){
            console.log('执行closeWindow');
            const url = location.href.split('#')[0];
            //let url = this.realAuthUrl
            //url = location.href.split('#')[0]
            const timestamp = Date.parse(new Date());
            const noncestr = this.randomString(16);
            console.log('timestamp',timestamp);
            console.log('url',url);
            console.log('noncestr',noncestr);

            var that = this;

            getSignature(timestamp,noncestr,url).then(res=>{
                //console.log(res);
                wx.config({
                    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                    appId: 'wx7ebdabb02e6faa33', // 必填，公众号的唯一标识
                    timestamp: timestamp, // 必填，生成签名的时间戳
                    nonceStr: noncestr, // 必填，生成签名的随机串
                    signature: res,// 必填，签名
                    jsApiList: ['closeWindow'] // 必填，需要使用的 JS 接口列表
                });
                wx.ready(function(){
                    console.log('readyreadyreadyreadyready');
                    // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
                    wx.closeWindow();
                });

                wx.error(function(res){
                // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                    //that.$toast(res);
                    console.log('aaaaaaa',res);
                });
            }) 

        },





        randomString(len) {
            len = len || 32;
            var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
            var maxPos = $chars.length;
            var pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        }
    }
}
</script>

<style>

</style>