<template>
  <div style="padding: 3vw;position: relative;height: 100%;box-sizing: border-box;background-color: #f3f4f8;">
     <div style="background-color: white;padding: 1px 15px;">
      <div style="padding: 10px 0;border-bottom:#a6a6a6 1px solid ;">
        <div style="margin: 20px 0 10px 5px;text-align: start;font-weight: bold;">1.您的手机号已注销，如何修改登录手机号？</div> 
        <p style="text-align: start;margin: 5px;color: #808080;">回答：</p>
        <p style="text-align: start;margin: 5px;color: #808080;">可通过在线客服渠道进行有效身份信息的验证，后台管理员可通过凯纳智慧生态管理系统手动修改您的信息，请您不要担心!</p>
        <p style="text-align: start;margin: 5px;color: #808080;">您也可以使用凯纳智慧生态公众号-用户中心-申请服务功能，联系我们的线下运维服务人员对您的问题进行上门服务，具体上门时效需运维服务人员与您进行联系。</p>
      </div>
      <div style="padding: 10px 0">
        <div style="margin: 10px 0 10px 5px;text-align: start;font-weight: bold;">2.您的手机丢失，担心领取不到补贴？</div> 
        <p style="text-align: start;margin: 5px;color: #808080;">回答：</p>
        <p style="text-align: start;margin: 5px;color: #808080;">可以到当地营业厅进行补卡操作后重新登录，您的补贴会在平台中依旧存在，尽快处理补卡问题即可!</p>
      </div>

     </div>
     <div style="position: absolute;bottom: 10vw;transform: translate(-50%);left: 50%;background-color: #409eff;color: white;height: 10vw;width: 90%;;line-height:10vw;border-radius: 1vw;" @click="takePhone">联系客服</div>
   
  </div>
</template>

<script>
export default {
  data() {
   return {
    phoneNumber:'4006551155',
   }
 },
 methods: {
    takePhone(){
      window.location.href='tel:'+this.phoneNumber;
    }    
 }
}
</script>

<style>

</style>