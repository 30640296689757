<template>
  <div class="container" style="position:relative">
    <div class="user" id="user" style="position:absolute;color: #fffcff;font-weight: 500;width: 100%;">
        <div style="font-size: 4vw;">当前用户：{{name}}</div> 
    </div>
    <div class="shanggeyue" id="shanggeyue" style="position:absolute;color:#adadad;width: 100%;">
        <div style="font-size: 4.5vw;color:white;font-weight: bold;letter-spacing: 1px">上个月补贴</div> 
    </div>
    <div id="dianfei" style="display: flex;justify-content: center;position:absolute;width: 100%;">
        <div style="width: 90vw;display: flex;justify-content: space-evenly;">
            <div>
                <div style="font-weight: bold;font-size: 6vw;width: 45vw;">{{currentMonth.electricity !=null ? parseFloat(currentMonth.electricity).toFixed(2) :'0.00'}}</div>
                <div style="font-size: 4vw;">用电量(度)</div>
            </div>
            <div>
                <div style="font-weight: bold;font-size: 6vw;width: 45vw;color: #ff9477;">{{(currentMonth.payAmount/100).toFixed(2) || '0.00'}}</div>
                <div style="font-size: 4vw;">电费(元)</div>
            </div>

        </div>
        
    </div>

    <div id="drawBtn" class="drawBtn" style="display: flex;justify-content: center;position:absolute;width: 100%;">
        <div v-if="currentMonth.status==null||currentMonth.status=='2'"
                @click="draw(currentMonth)" 
                style="background: linear-gradient(#fdd6bb,#ff6d48);padding: 2vw 3vw;color:white;font-weight: bold;font-size: 5vw;border-radius: 5vw;width:68vw">
                领&nbsp;取
            </div>
            <div v-else-if="currentMonth.status=='0'"
                style="background: #e3e3e3;padding: 1vw 3vw;color:white;font-weight: bolder;font-size: 5vw;border-radius: 5vw;width: 68vw;">
                转&nbsp;账&nbsp;中
            </div>
            <div v-else 
                style="background: #e3e3e3;padding: 1vw 3vw;color:white;font-weight: bolder;font-size: 5vw;border-radius: 5vw;width: 68vw;">
                已&nbsp;领&nbsp;取
            </div>
    </div>
   
    <!-- <div class="lingqu" id="lingqu" style="position:absolute;color: #fffcff;font-weight: bolder;width: 100%;">
        <div style="font-size: 4vw;letter-spacing: 5px" @click="draw(currentMonth)">领取</div> 
    </div> -->

    <div class="jiesuan" id="jiesuan" style="position:absolute;color:#adadad;width: 100%;">
        <div style="font-size: 3.5vw;">每月1日结算后可领取</div> 
    </div>

    <div class="sangeyue" id="sangeyue" style="position:absolute;color:#adadad;width: 100%;">
        <div style="font-size: 4.5vw;color:white;font-weight: bold;letter-spacing: 1px">六个月内补贴情况</div> 
    </div>
    <div class="bannian" id="bannian" style="display: flex;flex-direction: column;align-items: center;position:absolute;width: 100%;">
        <div v-for="(item,index) in deviceData" :key="index" :style="'display: flex;justify-content: space-between;align-items: center;width: 75%;height: 15vw; '">
            <div style="text-align: start;">
                <div style="display: flex;align-items: center;" >
                    <span style="font-weight: bold;font-size: 6vw;margin-right: 2vw;">{{ (item.payAmount/100).toFixed(2) }}</span>
                </div>
                <div style="font-size: 3.5vw;">{{item.belongMonth}}</div>
            </div>
            <div v-if="item.status == '2'" style="color: #f73131;font-size: 3vw;">
                <div>上次打款异常!</div>
                <!-- <div>{{item.remark}}</div> -->
            </div>
            <!-- <img v-if="index==0" src="../assets/lingqu.png" style="height: 8vw;" @click="draw"> -->
            <div v-if="item.status==null||item.status=='2'"
                @click="draw(item)" 
                style="background: linear-gradient(#fdd6bb,#ff6d48);padding: 1vw 3vw;color:white;font-weight: bold;font-size: 4vw;border-radius: 5vw;width: 15vw">
                领&nbsp;取
            </div>
            <div v-else-if="item.status=='0'"
                style="background: #e3e3e3;padding: 1vw 3vw;color:white;font-weight: bolder;font-size: 4vw;border-radius: 5vw;width: 15vw;">
                转&nbsp;账&nbsp;中
            </div>
            <div v-else 
                style="background: #e3e3e3;padding: 1vw 3vw;color:white;font-weight: bolder;font-size: 4vw;border-radius: 5vw;width: 15vw;">
                已&nbsp;领&nbsp;取
            </div>
            <!-- <img v-else src="../assets/yilingqu.png" style="height: 8vw;"> -->
        </div>
        
    </div>


    <div class="zhuyi" id="zhuyi" style="position:absolute;color:#848485;width: 100%;">
        <div style="font-size: 3.5vw;">注：当前仅支持查看半年内领取补贴记录。</div> 
    </div>
    <!-- <div class="card" >
        {{ deviceId }}
       
    </div> -->
  </div>
</template>

<script>
import { getDataAndMonth,getSubsidy,selectUserSubsidyList } from "@/api/wushui/wushui";

export default {
    data() {
        return {
            deviceId:null,
            name:{},
            deviceData:[],
            currentMonth:{electricity:0.00,payAmount:0.00},
            theToast:null,
            selectMonth:4
        };
    },

    mounted(){
        this.deviceId = this.$route.query.deviceId;
        this.name = this.$route.query.name;
        console.log( this.deviceId + this.name);
        this.initPage();
        this.getData();
    },

    methods: {

        //领取
        draw(item){
            this.theToast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration:0
            });
            
            let subsidyList = [];
            subsidyList.push(item)
            //query.status = item.status
            let that = this;
            getSubsidy(subsidyList).then(res=>{
                //that.$toast.clear();
                console.log('getSubsidy返回数据',res.msg);
                that.$toast(res.msg || '领取成功！');
                setTimeout(() => {
                    that.getData();
                }, 1500);
                
            }).catch(err=>{
                that.$toast('领取失败');
                setTimeout(() => {
                    that.getData();
                }, 1500);
            })

            //刷新页面

        },

        getData(){
            this.theToast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration:0
            });
            var that = this;
            const param = {
                deviceId: this.deviceId
            }
            selectUserSubsidyList(param).then(res=>{
                console.log(res.data);
                this.deviceData = res.data
                
                if(this.deviceData.length > 0){
                    this.currentMonth = this.deviceData[0]
                }
                this.deviceData = this.deviceData.slice(1)
                that.theToast.clear();
                
               
            })


        },

        initPage(){
            const currentUserEle = document.getElementById("user");
           
            const shanggeyue = document.getElementById("shanggeyue");

            const dianfei = document.getElementById("dianfei");
           
            const drawBtn = document.getElementById("drawBtn");
           
            const jiesuan = document.getElementById("jiesuan");
           
            const sangeyue = document.getElementById("sangeyue");
           
            const bannian = document.getElementById("bannian");
           
            const zhuyi = document.getElementById("zhuyi");
            
            // 正常3个月版本
            //zhuyi.style.top = "207.5vw";
            //currentUserEle.style.top = "36.5vw";
            // dianfei.style.top = "114vw";
            // drawBtn.style.top = "131vw"
            // jiesuan.style.top = "145.5vw";
            // sangeyue.style.top = "163.5vw";
            // bannian.style.top = "172.5vw";


            // 4个月版本
            // zhuyi.style.top = "227.5vw";           
            // currentUserEle.style.top = "40.5vw";             
            // dianfei.style.top = "118vw";            
            // drawBtn.style.top = "135vw"              
            // jiesuan.style.top = "149.5vw";            
            // sangeyue.style.top = "166.5vw";          
            // bannian.style.top = "176.5vw";

            //6个月版本
            currentUserEle.style.top = "27.5vw";      
            shanggeyue.style.top = "90.5vw";
            dianfei.style.top = "105vw";  
            drawBtn.style.top = "122vw"              
            jiesuan.style.top = "136.5vw";            
            sangeyue.style.top = "153.5vw";          
            bannian.style.top = "163.5vw";
            zhuyi.style.top = "244.5vw";           
                  
                       
            
            
        }

    },



}
</script >

<style lang="scss" scoped>



.container {
    width: 100vw;
    height: 267vw;
    background-image: url("../assets/bg_temp6.png") ;
    background-size:100% auto ;
    background-repeat: no-repeat;
    background-position: center;
    overflow: auto;
  }


</style>