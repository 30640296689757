<template>
  <div>申请工单</div>



</template>

<script>
export default {
  data() {
      return {

      };
    },

    created(){
      //this.initPage();
    },

    methods: {



    },






}
</script>

<style>

</style>