import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import wx from 'weixin-js-sdk'
import { Button,Popup, NavBar,Field,Toast,Image as VanImage,Icon,Dialog,Cell,CellGroup, RadioGroup, Radio ,DropdownMenu, DropdownItem,Empty ,Popover,Uploader } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Button);
Vue.use(Popup);
Vue.use(NavBar);
Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Empty);
Vue.use(Popover);
Vue.use(Uploader);
// import { Tabbar, TabbarItem } from 'vant';
Vue.prototype.$wx = wx
// Vue.use(Tabbar);
// Vue.use(TabbarItem);


Vue.config.productionTip = false


new Vue({
  router,
  store,
  ElementUI,
  render: h => h(App)
}).$mount('#app')

