<template>
  <div class="a" >
    <!-- <div><button @click="queryDeviceList()">aaa</button></div> -->
    <div class="card" v-for="(data,index) in deciveList" :key="index" >
        
        <div style="display:flex;justify-content: space-between;padding: 12px 5px 12px 16px;">
            <div style="display: flex;align-items: center">
                <img src="../assets/设备图.png" alt="" style="height: 50px;margin-right: 10px;">
                <div style="">
                    <div style="font-weight:bold;text-align: start;margin-bottom: 5px;">{{ data.name }}</div>
                    <div style="color: #9c9c9c;font-size: 15px;">{{data.id}}</div>
                </div>
            </div>
            
            <div @click="checkDetail(data)" style="text-align: top;margin-top: 5px;color: #4fbfe4;font-size: 15px;">
                查看<van-icon name="arrow" style="margin-right: 6px;" />
            </div>
        </div>
        <div class="btm" style="">
            <div id="leftCover"></div>
            <div id="rightCover"></div>
           
            <div class="b1" style="color: #9c9c9c;font-size: 15px;"> <van-icon name="location-o" style="margin-right: 6px;" />{{data.address}}</div>

        </div> 
    </div>

    <van-empty v-if="emptyFlag == 1" image="error" description="未查询到设备信息" />
  </div>
</template>

<script>

import { getDeviceList } from "@/api/wushui/wushui";
export default {
    data() {
        return {
            deciveList:[],
            emptyFlag:0
        };
    },

    created(){
       this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration:0
        });
        this.queryDeviceList();
    },

    methods: {

        //查询设备列表
        queryDeviceList(){
            console.log("11111")
            getDeviceList().then(res => {
                console.log('queryDeviceList',res);
                this.deciveList = res.data;
                if(this.deciveList == null || this.deciveList.length == 0){
                    this.emptyFlag = 1
                }
                this.$toast.clear();
                if(this.deciveList.length == 1){
                    this.$router.push('/searchDetail?deviceId='+this.deciveList[0].id+'&name='+this.deciveList[0].name);
                }
            }).catch(error => {
                //this.$router.push('/login?url=myDevice');
                location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/&response_type=code&scope=snsapi_base&state=myDevice#wechat_redirect'

            })

        },

        //查看设备详情
        checkDetail(data){
            this.$router.push('/searchDetail?deviceId='+data.id+'&name='+data.name);
        }

    },



}
</script >

<style lang="scss" scoped>

.btm{
    position: relative;
    display:flex; 
    justify-content: space-between;
    border-top: 1px solid #f3f4f8;
    padding: 5px 16px 10px 16px;

    &::before, &::after{
        content: '';
    }

    &::before, &::after, #leftCover, #rightCover {
        position: absolute;
        left: -6px;
        top:0;
        bottom: 0;
        margin: auto;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #f3f4f8;
        z-index: 1;
        box-shadow: inset -6px 2px 6px -4px rgba(34,91,128,0.19);
    }

    &::after, #rightCover{
        left: inherit;
        right: -6px;
        box-shadow: inset 4px 1px 4px 0px rgba(34,91,128,0.19);
    }

    #leftCover, #rightCover{
        box-shadow: none;
        width: 24px;
        height: 24px;
        filter: blur(5px);
        z-index: 2;
    }

    #leftCover{
        left: -20px;
    }

    #rightCover{
        right: -25px;
        top: -0px;
    }
}

.card{
    
    background-color:white;
    border-radius: 3px;
    margin: 22px 0;
    box-shadow: 4px 4px 6px 0px rgba(34,91,128,0.19);
    

}





.a{
    background-color:#f3f4f8;
    height: 100%;
    overflow:auto;
    padding: 0 17px;
    box-sizing: border-box;
}
.a::-webkit-scrollbar{
    display:none;
}



.applyFee{
    padding: 3px 0;
    width: 17vw;
    border-radius: 15px;
    background-image: linear-gradient(to right, rgba(252, 160, 83, 1), rgba(250, 87, 23, 1));
    color: white;
}
.expire{
    padding: 3px 0;
    width: 17vw;
    border-radius: 15px;
    background-color: #bbbbbb;
    color: white;
}
</style>