<template>
    <div style="position:relative;padding: 30px;">
        <img src="../../assets/success.png" alt="" style="margin:60px 0 20px 0; width: 40%;" />
        <div style="font-size:5vw">提交成功</div>
        <p style="margin:30px;color: #a6a6a6;text-align:center;">
           您的用户信息修改申请已提交至后台管理系统，为了您的信息安全，管理员将通过人工审核、电话回访等方式进行验证，请您理解。
        </p>
        <div @click="backToUserCenter" style="padding: 10px 20px;border-radius: 100px;border: 1px solid #3fa2fe;"> 
            <div>返回主页</div>
        </div>
    </div>
  
</template>

<script>
export default {
    data() {
        return {
          
        };
    },

    created(){
       
       
    },

    methods: {

        
        backToUserCenter(){
            this.$router.push('/userCenter');
    
        }

    },
}
</script>

<style>

</style>