<template >
    <div style="background-color: #f5f3f6;min-height: 100vh;display: flex;flex-direction: column;">
        <div style="display: flex;justify-content: space-between;padding:0 3vw;background-color: white;padding: 7vw 5vw;">
            
            <div style="text-align: start;margin-bottom: 5vw;">
                <div style="margin-bottom: 2vw;font-weight:600;font-size: 4vw;">
                    {{ userInfo.name }}
                    
                </div>
                <div style="margin-bottom: 1vw;color: #9c9c9c;font-size: 3.5vw;">{{userInfo.tel}}</div>
                <div style="margin-bottom: 1vw;color: #9c9c9c;font-size: 3.5vw;">{{userInfo.address}}</div>
                <div >
                    <span style="color: #9c9c9c;font-size: 3.5vw;">设备编号：</span>
                    <span style="color: #9c9c9c;font-size: 3.5vw;" >{{getDeviceIdFront(userInfo.id)}}</span>
                    <span v-if="deciveList.length > 1" @click="chosesDevice" style="color: #3486d0;font-size: 3.5vw;font-weight: 500;margin-left: 3vw;">切换设备>></span>
                </div>
            </div>
            <div style="display: flex;flex-direction: column;justify-content: space-around;margin-bottom: 5vw;">
                <!-- <img src="../assets/设备图.png" alt="" style="height: 15vw;"> -->
                
                <van-popover @select="onSelect" v-model="showPopover" theme="dark" trigger="click" :actions="actions" placement="bottom-end" >
                    <template #reference>
                        <van-icon name="setting" size="6vw" />
                    </template>
                </van-popover>
            </div>
            
        </div>
        <div style="display: flex;justify-content: space-around;border-radius: 5px;box-shadow: 0 0 2px 2px #e6e3e6;padding: 3.8vw;margin:-5vw 4vw 3vw 4vw;background-color: white;">
            <div style="flex:1;border-right: 1px solid #f5f3f6;">
                <div style="color: #9c9c9c;font-size: 3.5vw;margin-bottom: 2vw;">已成功领取</div>
                <span style="font-weight:600;font-size: 6vw;margin-right: 2px;">{{this.successMap.totalCount||0}}</span><span style="font-size: 3.5vw;">次</span>
            </div>
            <div style="flex:1;border-left: solid 1px #f5f3f6;">
                <div style="color: #9c9c9c;font-size: 3.5vw;margin-bottom: 2vw;">已领取金额</div>
                <span style="font-weight:600;font-size: 6vw;margin-right: 2px;">{{((this.successMap.totalAmount||0)/100).toFixed(2)}}</span><span style="font-size: 3.5vw;">元</span>
            </div>
        </div>
        <div style="display: flex;flex-direction: column;flex:1;border-radius: 5px;box-shadow: 0 0 2px 2px #e6e3e6;padding: 3.8vw;margin:0vw 4vw 4vw 4vw;background-color: white;">
            <div style="text-align: start;border-bottom: solid 2px #f5f3f6;padding-bottom: 2vw;">领取记录</div>
            <div v-if="subsidyList.length == 0 ||subsidyList==null" style="flex:1;display: flex;flex-direction: column;align-items: center;justify-content: center;"> 
                <img src="../assets/empty.png" width="100" height="100">
                <div style="color: #bfbfbf;font-size: 4vw;margin-top: 5vw;">暂无记录</div>
            </div>
            
            <div v-for="(sub,ind) in subsidyList" :key="ind" style="padding: 2vw 2vw;border-bottom: solid 2px #f5f3f6;"> 
                <div style="text-align: start;">
                    <div style="display: flex;justify-content: space-between;margin-bottom: 2px;align-items: center;">
                        <div >
                            <span style="font-weight: 600;font-size: 4vw;">{{ sub.belongMonth }}</span>
                            <span v-if="sub.status==0" style="color: white;background-color: #3190e2;font-size: 3.5vw;padding: 0.5vw 2vw;border-radius: 1vw;margin-left: 2vw;">申请中</span>
                            <span v-if="sub.status==1" style="color: white;background-color: #cdcac9;font-size: 3.5vw;padding: 0.5vw 2vw;border-radius: 1vw;margin-left: 2vw;">已领取</span>
                            <span v-if="sub.status==2" style="color: white;background-color: #cd323d;font-size: 3.5vw;padding: 0.5vw 2vw;border-radius: 1vw;margin-left: 2vw;">领取失败 !</span>
                        </div>
                        <div style="font-weight: 500;font-size: 4.5vw;">
                            <span v-if="sub.status!=2">+</span>
                            <span :style="sub.status==2 ? 'color: #9c9c9c;text-decoration:line-through;':''">{{(sub.payAmount/100).toFixed(2)}}</span>
                            
                        </div>
                    </div>
                    
                    <div style="font-size: 3.5vw;color: #9c9c9c;margin-bottom: 2px;">
                        <span>申请时间：</span>
                        <span>{{ sub.applyTime }}</span>
                    </div> 
                    <div v-if="sub.status == '1'" style="font-size: 3.5vw;color: #9c9c9c;">
                        <span>到账时间：</span>
                        <span>{{ sub.drawTime }}</span>
                    </div>
                    <div v-if="sub.status == '2'" style="font-size: 3.5vw;color: #9c9c9c;">
                        <span>失败原因：</span>
                        <span>{{ sub.remark }}</span>
                    </div>
                </div>
            </div>
            <infinite-loading :on-infinite="onInfinite" spinner="bubbles" :distance="120" ref="infiniteLoading">
                <div slot="no-more" style="color: #bfbfbf;font-size: 4vw;margin-top: 3vw;">没有更多了</div>
                <div slot="no-results"></div>
            </infinite-loading>
           
        </div>
        <div style="height: 1px;"></div>
        <van-dialog v-model="showChoses" title="选择设备" show-cancel-button style="padding: 10px;" @confirm="confirmChoses">
            
            <div>
                <van-radio-group v-model="radio">
                    <van-cell-group>
                        <van-cell v-for="(device,index) in deciveList" :key="index" :title="deciveList[index].id" clickable @click="radio = index+''">
                            <template #right-icon>
                                <van-radio :name="index+''" />
                            </template>
                        </van-cell>
                       
                    </van-cell-group>
                </van-radio-group>
            </div>
        </van-dialog>

       
        
    </div>
    
    
</template>

<script>
import wx from 'weixin-js-sdk'
import {querySubsidyByDeviceId, getDeviceList,querySuccessMoney} from '@/api/wushui/wushui'
import {logofUser} from '@/api/system/login'
import InfiniteLoading from 'vue-infinite-loading'

export default {
    components: {
     InfiniteLoading
    },
    data(){
        return {
           userInfo:{
            name:'',
            tel:'',
            address:'',
            deviceId:''
            },
            showChoses:false,
            radio: '0',
            deciveList:[],
            subsidyListTest:[],
            subsidyList:[],
            queryParam:{
                pageNum: 1,
                pageSize: 10,
                orderByColumn:'belongMonth',
                isAsc:'desc',
                status:null,
                deviceId:''
            },
            successMap:{},
            showPopover: false,
            
            // 通过 actions 属性来定义菜单选项
            actions: [{ text: '修改信息',className:'1' }, { text: '注销登录',className:'2' }],
            // actions: [{ text: '注销登录',className:'2' }],
            list: 10, // 上拉加载的数据
            page: 1, // 当前页码
            size: 10, // 每页数量
            isLoading: false, // 是否正在加载中
            total:0
        }
    },
    mounted(){
        
        this.initPage()



    },

    computed: {
        // getDeviceIdFront (id) {
        //     console.log(id);
        //     return id.split("-")[0];
        // }
    },
    
    methods:{
        getDeviceIdFront (deviceid) {
            //return id.split("-")[0];
            if(!deviceid){
                deviceid = " - "
            }
            var idfront = deviceid.split("_");
            console.log(idfront);
            return idfront[0]
        },

        // 上拉加载回调事件
        onInfinite($state) {
            console.log('执行onInfinite方法');
            if(this.subsidyList.length != 0){
                if (this.subsidyList.length < this.total ) {
                    setTimeout(() => {
                        this.queryParam.pageNum ++; // 下一页
                        //this.queryParam.pageSize = 2
                        querySubsidyByDeviceId(this.queryParam).then(res=>{
                            console.log(res);
                            this.subsidyList = this.subsidyList.concat(res.rows);
                            console.log('this.subsidyList'+this.subsidyList.length)
                            $state.loaded();
                        })
                    }, 1000);
                    
                    console.log(' $state.loaded();');
                } else {
                    console.log(' $state.complete();1');
                    $state.complete();
                }
            }else{
                console.log(' $state.complete();this.subsidyList.length==0');
                $state.complete();
            }
        },


        initPage(){
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            getDeviceList().then(res => {
                this.deciveList = res.data
                if(this.deciveList == null || this.deciveList.length ==0){
                    this.userInfo.id='无设备'
                    return;
                }
                
                this.userInfo = this.deciveList[0]
                this.queryParam.deviceId = this.userInfo.id;
                console.log(this.queryParam)
                querySubsidyByDeviceId(this.queryParam).then(res=>{
                    console.log(res);
                    this.subsidyList = res.rows;
                    this.total = res.total

                })
                querySuccessMoney(this.userInfo.id).then(res=>{
                    console.log('querySuccessMoney',res);
                    this.successMap = res.data
                })
                this.$toast.clear();
            }).catch(err=>{
                //this.$router.push('/login?url=userCenter');
                location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/&response_type=code&scope=snsapi_base&state=userCenter#wechat_redirect'
            })
            

        },

        chosesDevice(){
            this.showChoses = true;
        },
        
        //确认选择设备
        confirmChoses(){

            this.subsidyList =[]
            this.userInfo = this.deciveList[this.radio]
            this.queryParam.deviceId = this.userInfo.id;
            this.queryParam.pageNum = 1;
            console.log(this.queryParam)
            
            //this.Pagestate.reset();

            querySubsidyByDeviceId(this.queryParam).then(res=>{
                console.log(res);
                this.subsidyList = res.rows;
                this.total = res.total;
                this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            querySuccessMoney(this.userInfo.id).then(res=>{
                console.log(res.data);
                this.successMap = res.data
            })
        },
        onSelect(action) {
            var that = this
            if(action.className=='1'){
                this.$router.push('/editInfo');
            }else if(action.className=='2'){
                this.$dialog.confirm({
                    title: '是否确认注销？',
                })
                .then(() => {
                    window.localStorage.clear();
                    that.$router.push('/logoutsuccess');
                    // logofUser().then(res=>{
                    //     window.localStorage.clear();
                    //     that.$router.push('/logoutsuccess');
                    // })                    
                })
                .catch(() => {
                    // on cancel
                });

                
            }

        },
    }
}
</script>

<style>

</style>