<template>
  <div>
    <div class="pageOne"  style="position: relative;min-height: 100vh;background-color: #f3f4f8;padding: 10px;box-sizing: border-box;">
      <div style="display: flex;border-radius:5px;margin: 20px 0 ;background-color: white;font-size: 14px;padding:16px;">
        <div style="text-align: start;margin-right: 12px;width: 6.2em;">问题类型：</div>
        <el-dropdown @command="handleCommand1" trigger="click"> 
          <span class="el-dropdown-link">
            {{userInfo.reasonType}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" >
            <div v-for="(op,ind) in option1" :key="ind">
              <el-dropdown-item :command="op">{{op.parameterValue}}</el-dropdown-item>
            </div>
            
            
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="display: flex;border-radius:5px;margin: 20px 0 ;background-color: white;font-size: 14px;padding:16px;">
        <div style="text-align: start;margin-right: 12px;width: 6.2em;">修改类型：</div>
        <el-dropdown @command="handleCommand2" trigger="click"> 
          <span class="el-dropdown-link">
            {{userInfo.category}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" >
            <div v-for="(op,ind) in option2" :key="ind">
              <el-dropdown-item :command="op">{{op.parameterValue}}</el-dropdown-item>
            </div>
            
            
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="border-radius:5px;">
          <van-field v-model="userInfo.name" label="姓名："  placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      <div  style="border-radius:5px;margin: 20px 0 ;font-size: 4vw;">
          <van-field v-model="userInfo.cardNumber" type="idcard" label="身份证号：" placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      <div  style="border-radius:5px;margin: 20px 0 ;font-size: 4vw;">
          <van-field v-model="userInfo.newTelePhone" label="手机号码：" type="number" placeholder="请输入..." style="border-radius:5px;"/>
      </div>
      
      <div style="display: flex;align-items: center;border-radius:5px 5px 0 0;margin-top: 20px ;background-color: white;font-size: 14px;padding:10px 10px 10px 16px;">
          <!-- <van-field v-model="userInfo.newAddr" label="所在城市：" placeholder="请选择..." readonly  style="border-radius:5px;" @click="showPopup"/> -->
          <div style="text-align: start;margin-right: 12px;width: 6.2em;">市：</div>
          <el-select v-model="cityVal" placeholder="请选择" filterable @change="changeCity">
            <el-option
              v-for="item in citys"
              :key="item.id"
              :label="item.label"
              :value="item">
            </el-option>
          </el-select>
      </div>

      <div style="display: flex;align-items: center;margin: 0 ;background-color: white;font-size: 14px;padding:10px 10px 10px 16px;">
          <!-- <van-field v-model="userInfo.newAddr" label="所在城市：" placeholder="请选择..." readonly  style="border-radius:5px;" @click="showPopup"/> -->
          <div style="text-align: start;margin-right: 12px;width: 6.2em;">县：</div>
          <el-select v-model="xianVal" placeholder="请选择" filterable @change="changeCity">
            <el-option
              v-for="item in xians"
              :key="item.id"
              :label="item.label"
              :value="item">
            </el-option>
          </el-select>
      </div>

      <div style="display: flex;align-items: center;border-radius:0 0 5px 5px;margin-bottom: 20px ;background-color: white;font-size: 14px;padding:10px 10px 10px 16px;">
          <!-- <van-field v-model="userInfo.newAddr" label="所在城市：" placeholder="请选择..." readonly  style="border-radius:5px;" @click="showPopup"/> -->
          <div style="text-align: start;margin-right: 12px;width: 6.2em;">村屯：</div>
          <el-select v-model="cuntunVal" placeholder="请选择" filterable @change="changeCity">
            <el-option
              v-for="item in cuntuns"
              :key="item.id"
              :label="item.label"
              :value="item">
            </el-option>
          </el-select>
      </div>

      
      <div >
        <div style="border-radius:5px;margin: 20px 0 ;background-color: white;font-size: 14px;padding: 15px;">
          <div style="text-align: start;">身份证正面（必填）</div>
          <van-uploader :after-read="afterRead" v-model="card1" preview-size="200px" image-fit="contain" name="card1" :before-read="beforeRead"  >
            <div v-if="card1.length == 0" style="background-color: #f3f4f8;margin: 10px;border-radius: 5px;padding: 30px 0;width: 80vw;" @click="takePhoto('card1')"><img width="120px" height="120px" src="../assets/camera.png" /></div>
            <div v-else></div>
          </van-uploader>
        </div>
        <div style="border-radius:5px;margin: 10px 0 ;background-color: white;font-size: 14px;padding: 15px;">
          <div style="text-align: start;">身份证反面（必填）</div>
          <van-uploader :after-read="afterRead" v-model="card2" preview-size="200px" image-fit="contain" name="card2" :before-read="beforeRead" >
            <div v-if="card2.length == 0" style="background-color: #f3f4f8;margin: 10px;border-radius: 5px;padding: 30px 0;width: 80vw;" @click="takePhoto('card2')"><img width="120px" height="120px" src="../assets/camera.png" /></div>
            <div v-else></div>
          </van-uploader>
        </div>
      </div>
     
      <div style="height:150px;"></div>
      <div style="display: flex;flex-direction: column;position: absolute;bottom: 30px;transform: translate(-50%);left: 50%;width: 90%;">
       
        <van-button type="info" style=""
        @click="confirmOrder">提交</van-button>
      </div>

      
    </div>



    <!-- <van-popup v-model="show" position="bottom" round :style="{ height: '50%' }" >
      <div style="display: flex;justify-content: space-between;padding: 20px 30px;">
        <div @click="closeWin">取消</div>
        <div>确定</div>
      </div>
      <van-picker
        title="城市"
        show-toolbar
        :columns="citys"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup> -->
  
  </div>
  


</template>

<script>
import {changeTelOrder,getEditChangeTelPage,selectAddrByParent} from '@/api/wushui/wushui'
import * as imageConversion from 'image-conversion'
export default {
    data() {
        return {
          wxcode:'',
          userInfo:{
            name:'',
            cardNumber:'',
            newAddr:'',
            newTelePhone:'',
            operationWay:'1',
            category:'',
            reasonType:'',
            mapId:'',
            wxcode:''
          },
          card1:[],
          card2:[],
          fileMap:{},
          option1: [
          
          ],
          option2: [
          
          ],
          show: false,
          value1: 0,

          citys:[],//城市
          xians:[],//县
          cuntuns:[],//村屯
          cityVal:'',
          xianVal:'',
          cuntunVal:'',
          currentLevel:2,
        };
    },

    created(){
      const searchParams = new URLSearchParams(window.location.search);
      this.wxcode = searchParams.get('code');
      console.log('code',this.wxcode);
      this.userInfo.wxcode = this.wxcode;
      this.initPage();
       
    },

    methods: {

      //查询设备列表
      initPage(){
        getEditChangeTelPage().then(res=>{
          console.log(res.data);
          const reason_type =  res.data.order_reason_type[0].parameterValue;
          const edit_type = res.data.edit_type[0].parameterValue;

          this.userInfo.reasonType = reason_type;
          this.userInfo.category = edit_type;
          this.option1 = res.data.order_reason_type ;
          this.option2 = res.data.edit_type;
          this.citys = res.data.addrList

        })
      },

      changeCity(e){
        console.log(e);

        this.currentLevel = e.level;
        //选择城市 哈尔滨
        if( this.currentLevel == 2){
          this.cityVal = e.label
          this.xians = [];
          this.xianVal = '';
          this.cuntuns = [];
          this.cuntunVal = '';
        //选择县 巴彦
        }else if(this.currentLevel == 3){
          this.xianVal = e.label
          this.cuntuns = [];
          this.cuntunVal = '';
        //选择村屯
        }else if(this.currentLevel == 4){
          this.cuntunVal = e.label
        }

        this.userInfo.mapId = e.id
        this.userInfo.newAddr = this.cityVal+this.xianVal+this.cuntunVal;
        console.log(this.userInfo);

        this.$toast.loading({
          forbidClick: true,
          duration:500
        });

        selectAddrByParent(e.id).then(res=>{
          if( this.currentLevel == 2){
            this.xians = res.data
          }else if(this.currentLevel == 3){
            this.cuntuns = res.data
          }
        })

      },

      // 返回 Promise
      beforeRead(file) {
        return new Promise((resolve, reject) => {
          const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isJpgOrPng) {
            //自己封装的错误提示方法
            this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传图片大小不能超过 2MB!');
          }
          if (!isLt2M || !isJpgOrPng) {
            return false;
          }

          //压缩处理
          imageConversion.compressAccurately(file, 200).then(res => {
            console.log('res',res);
            resolve(res);
          });
        });


        

      
      },

      // onConfirm(value, index) {
      //   Toast(`当前值：${value}, 当前索引：${index}`);
      // },
      // onChange(picker, value, index) {
      //   Toast(`当前值：${value}, 当前索引：${index}`);
      // },
      // onCancel() {
      //   Toast('取消');
      // },

      // showPopup() {
      //   this.show = true;
      // },
      // closeWin(){
      //   this.show = false;
      // },

      handleCommand1(command) {
        this.userInfo.reasonType = command.parameterValue;
      },
      handleCommand2(command) {
        this.userInfo.category = command.parameterValue;
      },

      afterRead(file) {
        // 此时可以自行将文件上传至服务器
        console.log('afterRead',file);
      // console.log('oldCard1',this.oldCard1[0].file);
        

      },

      confirmOrder(){
        console.log('card1',this.card1[0].file);
        this.$dialog.confirm({
          title: '是否确认提交工单',
          //message: '弹窗内容',
        }).then(() => {
          let format = new window.FormData();
          if(this.card1.length == 0){
              this.$toast('身份证正面未上传！');
              return;
          }
          if(this.card2.length == 0){
            this.$toast('身份证背面未上传！');
            return;
          }
          format.append("card1",this.card1[0].file);
          format.append("card2",this.card2[0].file);
          
          format.append("userInfo", new Blob([JSON.stringify(this.userInfo)], {type: "application/json"}));
          console.log('fffff',format)

          changeTelOrder(format) .then(res=>{
            this.$router.push('/successPage');
          }).catch(error => {
            console.log('err',error);
            if(error.data.code != 200){
              console.log('提交失败，刷新页面')
              location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/#/changeTelNew&response_type=code&scope=snsapi_base&state=loginsuccess#wechat_redirect'
            }
          })

          
        });
      },

      nextPage(){
        if(!this.userInfo.oldName){
          this.$toast('原户主名为空！');
          return;
        }
        
        if(this.userInfo.oldIDType == '1'){
          if(!this.userInfo.oldNumber){
            this.$toast('原户主身份证号为空！');
            return;
          }
          if(this.oldCard1.length == 0){
            this.$toast('原户主身份证正面未上传！');
            return;
          }
          if(this.oldCard2.length == 0){
            this.$toast('原户主身份证背面未上传！');
            return;
          }
        }
        if(this.userInfo.oldIDType == '2'){
          if(!this.userInfo.oldNumber){
            this.$toast('原户主户口编号为空！');
            return;
          }
          if(this.oldBook.length == 0){
            this.$toast('原户主户口照片未上传！');
            return;
          }
        }
        this.$toast.loading({
          forbidClick: true,
          duration:1000
        });
        setTimeout(() => {
          this.pageNum = 2
        }, 1000);
       
      },
      back(){
        this.$toast.loading({
          forbidClick: true,
          duration:1000
        });
        setTimeout(() => {
          this.pageNum = 1
        }, 1000);
      },
    


    },
}
</script>

<style>

</style>